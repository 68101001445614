







































import BaseText from '@/components/text/BaseText.vue';
import { defineComponent, ref } from '@vue/composition-api';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

export default defineComponent({
  emits: ['setSelectedLanguage'],
  props: {
    language: {
      type: String,
      default: I18nLocaleEnum.en,
    },
  },
  components: {
    BaseText,
  },
  setup(props, { emit }) {
    const lang = ref(props.language);
    const changeLanguage = (language: string) => {
      lang.value = language;
      emit('setSelectedLanguage', language);
    };
    return {
      lang,
      I18nLocaleEnum,
      changeLanguage,
    };
  },
});
